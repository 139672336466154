import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';

import Icon from '@/shared/icons/icon.vue';
import CTimeCountdown from '@/components/c-time-countdown/c-time-countdown.vue';

import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';

import StatusTextEnId from '@/shared/data/status-text-en-Id.service';

@Component({
  components: {
    Icon,
    CTimeCountdown,
  },
  mixins: [StatusTextEnId],
})
export default class CDetailOrderStatus extends Mixins(StatusTextEnId) {
  // ================== START SERVICES ==================
  // ================== END SERVICES ==================

  // ================== START VARIABLES ==================
  @Prop({ default: null }) public orderStatus: CbCommonStatus;
  @Prop({ default: null }) public expiredDate: Date;
  @Prop({ default: null }) public aaltDate: Date;
  @Prop({ default: null }) public freezeDate: Date;
  @Prop({ default: null }) public bookStartDate: Date;
  @Prop({ default: null }) public dataCyCustom: string;
  @Prop({ default: null }) public keyName: string;
  @Prop({ default: null }) public workflowName: string;
  @Prop({ default: TypeComponent.DETAIL_ORDER }) public type: TypeComponent;
  @Prop({ default: false }) public isFreezeCountdown: boolean;
  @Prop({ default: false }) public isAlreadySendProjectDelivery: boolean;

  public cbCommonStatus = CbCommonStatus;
  public typeComponent = TypeComponent;

  // ================= END VARIABLES ==================

  // ================= START DEFAULT FUNCTION ==================
  // ================== END DEFAULT FUNCTION ==================

  // ================== START FUNCTION ==================
  @Emit('actionCountdown')
  public actionCountdown() {
    return;
  }
  // ================== END FUNCTION ==================

  // ================== START COMPUTE ==================
  public get getConditionTimeCountDownByStatus() {
    const countdown = {
      timeDate: null,
      timeFreezeDate: null,
      isFreezeCountdown: false,
      isVisibleCountdownZero: false,
    };

    switch (this.orderStatus) {
      case CbCommonStatus.WAITING_PAYMENT:
      case CbCommonStatus.FEEDBACK_NEEDED:
      case CbCommonStatus.SERVICE_FINISHED:
      case CbCommonStatus.REQUESTED:
        countdown.timeDate =
          this.orderStatus === CbCommonStatus.FEEDBACK_NEEDED || this.orderStatus === CbCommonStatus.SERVICE_FINISHED
            ? this.aaltDate
            : this.expiredDate;
        break;
      case CbCommonStatus.DISPUTED:
        if (this.isAlreadySendProjectDelivery) {
          countdown.timeDate = this.aaltDate;
          countdown.timeFreezeDate = this.freezeDate;
        } else {
          countdown.timeDate = this.expiredDate;
        }
        countdown.isFreezeCountdown = true;
        break;
      case CbCommonStatus.REVISION_REQUESTED:
        if ((this.keyName === 'REVISION_REQUESTED_EXTRA_FREE' || this.keyName === 'STATUS_PARTNER_WIN') && !this.isFreezeCountdown) {
          countdown.timeDate = this.expiredDate;
        } else {
          countdown.timeDate = this.aaltDate;
          countdown.timeFreezeDate = this.freezeDate;
          countdown.isFreezeCountdown = true;
        }
        break;
      case CbCommonStatus.LATE:
        countdown.timeDate = this.bookStartDate;
        break;
      case CbCommonStatus.ON_PROGRESS:
      case CbCommonStatus.OVERDUE:
        countdown.timeDate = this.expiredDate;

        if (
          this.workflowName === 'OR_FIELD_WORKER' &&
          this.orderStatus === CbCommonStatus.ON_PROGRESS &&
          (this.keyName === 'ON_PROGRESS_1' ||
            this.keyName === 'ADDITIONAL_4' ||
            this.keyName === 'ADDITIONAL_5' ||
            this.keyName === 'ADDITIONAL_6' ||
            this.keyName === 'ADDITIONAL_CANCELLED_1' ||
            this.keyName === 'ADDITIONAL_ACCEPT_1' ||
            this.keyName === 'ADDITIONAL_PAID_1')
        ) {
          countdown.isVisibleCountdownZero = true;
        }
        break;
    }

    return countdown;
  }

  public get getConditionTimeCountDownZero() {
    return (days, hours, minutes, seconds) => {
      if (days === '00' && hours === '00' && minutes === '00' && seconds === '00') {
        return true;
      }

      return false;
    };
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  // ================== END COMPUTE ==================

  // ================== START LISTENERS ==================
  // ================== END LISTENERS ==================
}
