import { Inject, Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CTimeCountdown from '@/components/c-time-countdown/c-time-countdown.vue';
import CInputOtp from '@/components/c-input-otp/c-input-otp.vue';
import PCbOidService from '@/services/cb-oid.service';
import { CbVerificationMethodType } from '@/shared/model/enumerations/cb-verification-method-type.model';
import { ICbOidActivation } from '@/shared/model/cb-oids.model';
import { CbVerificationOtp } from '@/shared/model/cb-verification-otp.model';
import { ICbVwUserActivation } from '@/shared/model/cb-vw-user-actiovation.model';
import axios, { CancelTokenSource } from 'axios';

@Component({ components: { CTimeCountdown, CInputOtp } })
export default class CVerificationPhone extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbOidService') public pCbOidService: () => PCbOidService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false, type: Boolean }) public visibleCountdown: boolean;
  @Prop({ default: false, type: Boolean }) public visibleFooter: boolean;
  @Prop({ default: false, type: Boolean }) public visibleHeader: boolean;
  @Prop({ default: null }) public value: CbVerificationOtp;
  public methodeVerification: CbVerificationMethodType;
  public visibleLoading: boolean = false;
  public showCountdown: boolean = false;
  public inputOtp: string = '';
  public invalidOtp: boolean = false;
  public timeCoundown: number = 3000;
  public timeMinutesOtp: number = 3;
  public countRequestOtp: number = 0;
  public isFetching: boolean = false;
  public cancelToken: CancelTokenSource = null;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.visibleCountdownListener();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public changeVerification() {
    this.$store.commit('isPhoneTrouble', false);
    (<any>this.$root).$emit('changePhoneVerification', this.value);
  }

  public resend() {
    this.visibleLoading = true;
    const activation: ICbOidActivation = {
      key: this.value.phoneActivationKey,
      activationCode: null,
    };
    this.pCbOidService()
      .resend(activation, 'phone')
      .then(res => {
        this.value.timeCountDown = null;
        if (res.countResend >= 3) {
          this.timeCoundown = this.timeCoundown + 12000;
          this.timeMinutesOtp += 12;
        }

        if (res.id == 1) {
          (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.resendOtp.success').toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Info',
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
          });

          this.$emit('input', res.detail);

          this.visibleCountdownListener();

          this.$root.$emit('dashboard::getCbVwUserActivation', (cbVwUserActivation: ICbVwUserActivation) => {
            cbVwUserActivation.phoneActivationKey = res.detail.phoneActivationKey;

            this.$root.$emit('dashboard::changeValue', 'cbVwUserActivation', cbVwUserActivation);
          });
        } else {
          (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.resendOtp.waited', { minutes: this.timeMinutesOtp }).toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Danger',
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000,
          });
        }
      })
      .catch(() => {
        (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.resendOtp.failed').toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Danger',
          variant: 'danger',
          solid: true,
          autoHideDelay: 3000,
        });
      })
      .finally(() => {
        this.visibleLoading = false;
      });
  }

  public handleInputOtp(isChanged?: boolean) {
    if (this.isFetching && !isChanged) return;
    if (this.inputOtp.length > 0) {
      this.inputOtp = this.inputOtp.toUpperCase();
    }
    if (this.inputOtp.length == 4) {
      this.isFetching = true;
      this.invalidOtp = false;
      this.visibleLoading = true;
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      const activation: ICbOidActivation = {
        key: this.value.phoneActivationKey,
        activationCode: this.inputOtp,
      };
      this.pCbOidService()
        .activate(activation, 'phone', this.cancelToken)
        .then(res => {
          this.visibleLoading = false;
          if (res.id && res.id > 0) {
            this.$emit('submit');
          } else {
            this.invalidOtp = true;
          }
        })
        .catch(err => {
          if (!axios.isCancel(err)) {
            this.visibleLoading = false;
            (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.toast.failedToSendPhoneNumberOTP').toString(), {
              toaster: 'b-toaster-top-center',
              title: 'Failed',
              variant: 'danger',
              solid: true,
              autoHideDelay: 3000,
            });
          }
        })
        .finally(() => {
          this.isFetching = false;
        });
    }
  }

  public changePhoneNumber() {
    this.$store.commit('isPhoneTrouble', false);
    (<any>this.$root).$emit('changePhoneVerification', this.value);
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get isPhoneTrouble(): boolean {
    return this.$store.getters.isPhoneTrouble;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('visibleCountdown')
  public visibleCountdownListener() {
    if (this.visibleCountdown) {
      this.showCountdown = true;
    }
  }

  @Watch('inputOtp.length')
  public inputOtpLengthListener(newValue: number, oldValue: number) {
    if (newValue != oldValue && this.isFetching) {
      this.handleInputOtp(true);
    }
  }
  // ================= END LISTENERS ==================
}
