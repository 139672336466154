import dayjs from 'dayjs';
import { Inject, Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import { Debounce } from '@/shared/decorator/debounce';

import Icon from '@/shared/icons/icon.vue';
import CButton from '../button/c-button.vue';
import CConfirmation from '../c-confirmation/c-confirmation.vue';
import CInputCurrency from '../c-input-currency/c-input-currency.vue';
import CInputPin from '../c-input-pin/c-input-pin.vue';
import CVoucherItem from '../c-voucher-item/c-voucher-item.vue';
import CInputNumber from '../c-input-number/c-input-number.vue';
import CInputPaymentPromo from './components/c-input-payment-promo/c-input-payment-promo.vue';
import CItemFpBankAccount from './components/c-item-fp-bank-account/c-item-fp-bank-account.vue';
import CFpBankAccount from './components/c-fp-bank-account/c-fp-bank-account.vue';
import CFpInputBalanceTab from './components/c-fp-input-balance-tab/c-fp-input-balance-tab.vue';
import CFpPaymentInfo from './components/c-fp-payment-info/c-fp-payment-info.vue';
import CFpInformation from './components/c-fp-information/c-fp-information.vue';
import CFpPaymentOptions from './components/c-fp-payment-options/c-fp-payment-options.vue';
import CCardPromoDetail from '@/components/c-card-promo-detail/c-card-promo-detail.vue';
import CLoader from '../c-loader/c-loader.vue';

import PCbBankAccountService from '@/services/cb-bank-account.service';
import PCbTopupService from '@/services/cb-topup.service';
import PCbVwPromoUserAggService from '@/services/cb-vw-promo-user-agg.service';
import GlobalService from '@/services/global.service';
import PPayService from '@/services/pay.service';
import PCbWithdrawService from '@/services/cb-withdraw.service';
import CCompletePaymentVa from '@/components/c-complete-payment-va/c-complete-payment-va.vue';
import PCbUserPinService from '@/services/cb-user-pin.service';
import AlertService from '@/shared/alert/alert.service';
import FormUtils from '@/shared/utils/form-utils.service';
import AppWorkflow from '@/app-workflow';
import JhiDataUtils from '@/shared/data/data-utils.service';

import { PayType } from './../../shared/model/enumerations/pay-type.model';
import { IPayPayload2023, PayPayload2023 } from './../../shared/model/pay-payload-2023.model';
import { ICbBankAccount, CbBankAccount } from '@/shared/model/cb-bank-account.model';
import { ICbTopup, CbTopup } from '@/shared/model/cb-topup.model';
import { IPayMethod } from '@/shared/model/pay-method.model';
import { IPayOptionVA } from '@/shared/model/pay-option-va.model';
import { IPayDetailVA, PayDetailVA } from '@/shared/model/pay-detail-va.model';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { ICbVerificationOtp } from '@/shared/model/cb-verification-otp.model';
import { BankAccountType } from '@/shared/model/enumerations/bank-account-type.model';
import { PayMethodType } from '@/shared/model/enumerations/pay-method-type.model';
import { CbVwPromoUserAgg, ICbVwPromoUserAgg } from '@/shared/model/cb-vw-promo-user-agg.model';
import { CbPayMethodType } from '@/shared/model/enumerations/cb-pay-method-type.model';
import { CbWithdraw, ICbWithdraw } from '@/shared/model/cb-withdraw.model';
import { CbPaymentStatus } from '@/shared/model/enumerations/cb-payment-status.model';
import { SetPinType } from '@/shared/model/enumerations/set-pin-type.model';
import { CbVwPromo, ICbVwPromo } from '@/shared/model/cb-vw-promo.model';
import { CbPromoPayMethod } from '@/shared/model/enumerations/cb-promo-pay-method.model';
import { CbRequirementType } from '@/shared/model/enumerations/cb-requirement-type.model';
import { CbOrderType } from '@/shared/model/enumerations/cb-order-type.model';
import {
  BALANCE_LIMIT,
  TOAST_SUCCESS,
  TOAST_WARNING,
  TOPUP_LIMIT,
  VA_REMAINING_MINIMUM,
  CC_REMAINING_MINIMUM,
  CC_REMAINING_MAXIMAL,
} from '@/constants';
import { DeviceSizeType } from '@/shared/model/enumerations/device-size-type.model';

export enum CbListBank {
  BCA = 'BCA',
  MANDIRI = 'MANDIRI',
  BNI = 'BNI',
  BRI = 'BRI',
  BSI = 'BSI',
}

@Component({
  components: {
    CButton,
    CConfirmation,
    CInputCurrency,
    CVoucherItem,
    CInputPin,
    Icon,
    CInputPaymentPromo,
    CInputNumber,
    CItemFpBankAccount,
    CFpBankAccount,
    CFpInputBalanceTab,
    CFpPaymentInfo,
    CFpInformation,
    CFpPaymentOptions,
    CCompletePaymentVa,
    CLoader,
    CCardPromoDetail,
  },
  watch: {
    'newCbBankAccount.accountHolderName': {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 150;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.newCbBankAccount.accountHolderName = oldVal;
        }
        this.handleCheckHolderName(this.newCbBankAccount.accountHolderName);

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
  },
})
export default class CFormPayment2023 extends Mixins(FormUtils, AppWorkflow, JhiDataUtils) {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('pPayService') private pPayService: () => PPayService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('pCbTopupService') private pCbTopupService: () => PCbTopupService;
  @Inject('pCbVwPromoUserAggService') private pCbVwPromoUserAggService: () => PCbVwPromoUserAggService;
  @Inject('pCbBankAccountService') private pCbBankAccountService: () => PCbBankAccountService;
  @Inject('pCbUserPinService') private pCbUserPinService: () => PCbUserPinService;
  @Inject('pCbWithdrawService') private pCbWithdrawService: () => PCbWithdrawService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public payload: PayPayload2023;
  @Prop({ default: null }) public dataCyCustom: string;
  @Prop({ default: null }) public dataCyCustomTitle: string;
  @Prop({ default: null }) public dataCyCustomPriceOrderDetail: string;
  @Prop({ default: null }) public dataCyCustomPriceTotalOrder: string;
  @Prop({ default: null }) public dataCyCustomPriceTotalPayment: string;

  public localPayload: PayPayload2023 = new PayPayload2023();
  public localPayloadBak: PayPayload2023 = new PayPayload2023();
  public isFetchingCbBankAccount = false;
  public cbBankAccounts: ICbBankAccount[] = [];
  public newCbBankAccount: ICbBankAccount = new CbBankAccount();
  public PayMethodType = PayMethodType;
  public PayType = PayType;
  public cbVwPromoUserAggs: ICbVwPromoUserAgg[] = [];
  public payMethods: IPayMethod[] = [];
  public payOptionsVA: IPayOptionVA[] = [];

  public orderDetail: ICbVwPromoUserAgg = new CbVwPromoUserAgg();
  public isBackTopUp = false;

  public selectedBankAccountId: string = null;
  public deletedBankAccountIds: number[] = [];
  public isSavingTopup = false;
  public isSavingWithdraw = false;
  public isSavingPayment = false;
  public isFetchingOrderDetail = false;

  public visibleOptionPayment = false;
  public visibleOptionVA = false;
  public deviceSizeType = DeviceSizeType;

  // PIN
  public visiblePin = false;
  public passwordCurrentNotMatch = false;
  public pinNotMatch = false;

  // PROMO
  public isFetchingCbVwPromoUserAggs = false;

  // BUAT TOPUP DALEM FORM
  public visibleVA = false;
  public payDetailVA: IPayDetailVA = new PayDetailVA();
  public promoPayMethod = CbPromoPayMethod;
  public payTypePromo = PayType.ALL;

  public itemsPerPage = 10;
  public queryCount: number = null;
  public page = 1;
  public previousPage = 1;
  public propOrder = 'seq';
  public reverse = true;
  public totalItems = 0;
  public infiniteId = +new Date();
  public links = null;

  public topupLimit = TOPUP_LIMIT;
  public balanceLimit = BALANCE_LIMIT;
  public ccRemainingMinimum = CC_REMAINING_MINIMUM;
  public ccRemainingMaximal = CC_REMAINING_MAXIMAL;
  public vaRemainingMinimum = VA_REMAINING_MINIMUM;

  public cbVwPromo: ICbVwPromo = new CbVwPromo();
  public isOpenPromoDetail = false;
  public pawTitlePrice = '';

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========

  public created() {
    this.payloadListener();
    this.$root.$off('formPayment::getVariable');
    this.$root.$off('formPayment::getVariable', (fieldName: string, callback: any) => {
      this.getVariable(fieldName, callback);
    });
    this.$root.$off('formPayment::changeValue');
    this.$root.$on('formPayment::changeValue', (field, value) => {
      this.changeValue(field, value);
    });
    this.$root.$off('formPayment::changeFunction');
    this.$root.$on('formPayment::changeFunction', (functionName: string, param1?: any, param2?: any, param3?: any) => {
      this.changeFunction(functionName, param1, param2, param3);
    });

    console.log('payment', this.payload);
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public changeFunction(functionName: string, param1?: any, param2?: any, param3?: any) {
    this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
  }
  public changeValue(fieldName: string, value: any) {
    this[fieldName] = value;
  }
  public getVariable(fieldName: string, callback: any) {
    callback(this[fieldName]);
  }

  public isMethodTypeForDebit(): boolean {
    if (
      this.localPayload.payMethodType == PayMethodType.DISBURSEMENT ||
      this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_DETAIL ||
      this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME ||
      this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME_DETAIL ||
      this.localPayload.payMethodType == PayMethodType.REFUND_REQUEST ||
      this.localPayload.payMethodType == PayMethodType.REFUND_REQUEST_DETAIL
    ) {
      return true;
    }
    return false;
  }

  public retrieveCbBankAccount() {
    this.isFetchingCbBankAccount = true;
    const params: any = {};

    if (this.isMethodTypeForDebit()) {
      params['bankAccountType.equals'] = BankAccountType.DEBIT;
    } else {
      params['bankAccountType.equals'] = BankAccountType.CREDIT;
    }

    this.pCbBankAccountService()
      .retrieveByLogin(params)
      .then(res => {
        if (res?.data?.length > 0) {
          const cbBanks: ICbBankAccount[] = [...res.data];
          this.pushCbBankAccount(cbBanks);

          if (this.isMethodTypeForDebit()) {
            this.selectedBankAccountId = this.cbBankAccounts[0].id.toString();
          }
        }
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      })
      .finally(() => {
        this.isFetchingCbBankAccount = false;
      });
  }

  public pushCbBankAccount(cbBanks: ICbBankAccount[]) {
    for (const bank of cbBanks) {
      const tmpBank: ICbBankAccount = Object.assign({}, bank);
      if (tmpBank.expiredDate) {
        const tmpDate = dayjs(tmpBank.expiredDate).toDate();
        let month: string = (tmpDate.getMonth() + 1).toString();
        if (month.length < 2) {
          month = '0' + month;
        }
        let year: string = tmpDate.getFullYear().toString().substring(2);
        if (year.length < 2) {
          year = '0' + year;
        }

        tmpBank.expiryDateLabel = [month, year].join('/');
      }

      if (tmpBank.accountNumber) {
        let numberArr: string[] = [];
        numberArr = tmpBank.accountNumber.match(/.{1,4}/g);
        tmpBank.accountNumber = numberArr.join(' ');
      }
      this.cbBankAccounts.push(tmpBank);
    }
  }

  public async checkOrderDetail() {
    this.isFetchingOrderDetail = true;

    this.pPayService()
      .pay2023(this.localPayload)
      .then(res => {
        this.orderDetail = { ...res.detail };

        if (this.isBackTopUp && res.reqType == 'VA_ACTIVE') {
          this.visibleVA = true;
          this.payDetailVA = { ...res.detail };
        }

        if (
          this.orderDetail.pgAmountPaid &&
          this.orderDetail.pgAmountPaid > 0 &&
          !this.localPayload.payTypes.includes(PayType.BALANCE_AUCTION_GUARANTEE)
        ) {
          this.localPayload.payTypes.push(PayType.BALANCE_AUCTION_GUARANTEE);
        }

        if (this.localPayload.payTypes?.length == 1) {
          this.cbVwPromoUserAggs = this.processPromoMethodAndPayType(this.cbVwPromoUserAggs, this.localPayload.payTypes[0]);
        }

        if (this.localPayload.promoCode && !this.orderDetail.promoId) {
          (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.toast.unableToUseTheSelectedPromoCode').toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Info',
            variant: 'warning',
            solid: true,
            autoHideDelay: 3000,
          });
          this.localPayload.promoCode = null;
        }
      })
      .catch(error => {
        if (error.response.status < 500) {
          this.processResponseSubmitPayment(error.response.data);
        } else {
          this.alertService().showHttpError(this, error.response);
        }
      })
      .finally(() => {
        this.isFetchingOrderDetail = false;
      });
  }

  public handlePayTypeClick(e) {
    if (!this.localPayload.payTypes) {
      this.localPayload.payTypes = [];
    }
    const value = <PayType>e.target.value;
    const foundIndex = this.localPayload.payTypes.findIndex(e => e == value);
    if (foundIndex >= 0) {
      Vue.delete(this.localPayload.payTypes, foundIndex);
    } else {
      this.localPayload.payTypes.push(value);
    }
    if (this.localPayload.payMethodType == PayMethodType.ORDER_PAW || this.localPayload.payMethodType == PayMethodType.ORDER_PAW_DETAIL) {
      this.processRetrieveCbVwPromoUserAggs();
    }

    this.checkOrderDetail();
  }

  public handleClickVA(code) {
    if (!this.localPayload.payTypes) {
      this.localPayload.payTypes = [];
    }
    const foundIndex = this.localPayload.payTypes.findIndex(e => e == PayType.VA);
    if (foundIndex < 0) {
      this.localPayload.payTypes.push(PayType.VA);
    }

    if (foundIndex >= 0 && this.localPayload.bankCode == code) {
      this.localPayload.bankCode = null;
      Vue.delete(this.localPayload.payTypes, foundIndex);
    } else {
      this.localPayload.bankCode = code;
    }

    const foundIndexCC = this.localPayload.payTypes.findIndex(e => e == PayType.CC);
    if (foundIndexCC >= 0) {
      Vue.delete(this.localPayload.payTypes, foundIndexCC);
    }

    if (this.localPayload.payMethodType == PayMethodType.ORDER_PAW || this.localPayload.payMethodType == PayMethodType.ORDER_PAW_DETAIL) {
      this.processRetrieveCbVwPromoUserAggs();
    }

    this.checkOrderDetail();
  }

  public handleClickCC(bankAccountId) {
    const foundIndex = this.localPayload.payTypes.findIndex(e => e == PayType.CC);
    if (foundIndex < 0) {
      this.localPayload.payTypes.push(PayType.CC);
    }

    if (foundIndex >= 0 && this.selectedBankAccountId == bankAccountId) {
      Vue.delete(this.localPayload.payTypes, foundIndex);
    }

    this.selectedBankAccountId = bankAccountId;

    if (this.localPayload.payTypes.length > 1) {
      this.cbVwPromoUserAggs = [];
    }

    const foundIndexVA = this.localPayload.payTypes.findIndex(e => e == PayType.VA);
    if (foundIndexVA >= 0) {
      Vue.delete(this.localPayload.payTypes, foundIndexVA);
    }

    if (this.localPayload.payMethodType == PayMethodType.ORDER_PAW || this.localPayload.payMethodType == PayMethodType.ORDER_PAW_DETAIL) {
      this.processRetrieveCbVwPromoUserAggs();
    }

    this.checkOrderDetail();
  }

  public processRetrieveCbVwPromoUserAggs() {
    if (this.localPayload.payTypes.length > 1) {
      this.page = 1;
      this.links = null;
      this.infiniteId += 1;
      this.cbVwPromoUserAggs = [];
      this.localPayload.promoCode = null;
      this.localPayload.promoPayMethod = null;
      this.payTypePromo = null;
    }

    if (
      this.localPayload.payTypes.length == 1 &&
      (this.localPayload.promoCode == null || this.localPayload.promoCode == '') &&
      this.payTypePromo != this.localPayload.payTypes[0]
    ) {
      let paytype = this.localPayload.payTypes[0];
      if (paytype == PayType.BALANCE_AUCTION_GUARANTEE || paytype == PayType.BALANCE_REFUND) {
        paytype = PayType.ALL;
      }

      this.page = 1;
      this.links = null;
      this.infiniteId += 1;
      this.cbVwPromoUserAggs = [];
      this.retrieveCbVwPromoUserAggService(paytype);
    }

    if (this.localPayload.payTypes.length == 0) {
      this.page = 1;
      this.links = null;
      this.infiniteId += 1;
      this.cbVwPromoUserAggs = [];
      this.retrieveCbVwPromoUserAggService(PayType.ALL);
    }
  }

  public retrieveCbVwPromoUserAggService(payType: PayType) {
    this.isFetchingCbVwPromoUserAggs = true;

    const pagination: any = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: [this.propOrder + ',' + (this.reverse ? 'desc' : 'asc')],
    };

    this.pCbVwPromoUserAggService()
      .retrieveByLoginBasedOnOrderIdReqPayType(this.localPayload.orderId, payType, pagination)
      .then(res => {
        this.payTypePromo = payType;

        if (res.data && res.data.length > 0) {
          for (const element of res.data) {
            this.cbVwPromoUserAggs.push(element);
          }

          if (res.headers['link']) {
            this.links = this.parseLinks(res.headers['link']);
          }

          if (this.localPayload.payTypes?.length == 1) {
            this.cbVwPromoUserAggs = this.processPromoMethodAndPayType(this.cbVwPromoUserAggs, this.localPayload.payTypes[0]);
          }
        }
        this.totalItems = Number(res.headers['x-total-count']);
        this.queryCount = this.totalItems;
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.isFetchingCbVwPromoUserAggs = false;
        if (<any>this.$refs.infiniteLoading) {
          (<any>this.$refs.infiniteLoading).stateChanger.loaded();
          if (this.links !== null && this.page > this.links['last']) {
            (<any>this.$refs.infiniteLoading).stateChanger.complete();
          }
        }
      });
  }

  public loadMore($state): void {
    if (!this.isFetchingCbVwPromoUserAggs) {
      this.page++;
      this.transition();
    }
  }

  public transition(): void {
    this.retrieveCbVwPromoUserAggService(this.payTypePromo);
  }

  public inputCardNumber(e: any) {
    const newString = e.target.value.replace(/\s/g, '');

    if (!Number(newString)) {
      return newString.replace(/[^,\d]/g, '');
    } else if (e.target.value) {
      const v = e.target.value
        .replace(/\s+/g, '')
        .replace(/[^,\d]/g, '')
        .replace(/[^\d]/gi, '');
      const matches = v.match(/\d{4,16}/g);
      const match = (matches && matches[0]) || '';
      const parts = [];
      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }
      let result = '';
      if (parts.length) {
        result = parts.join(' ');
      } else {
        result = e.target.value;
      }
      return result;
    } else {
      return null;
    }
  }

  public formatExpiredDate(e) {
    const code = e.keyCode;
    const allowedKeys = [8];

    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
    return e.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        '0$1/' // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        '$1/' // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        '0$1/$2' // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([\d]{2})$/g,
        '$1/$2' // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        '0' // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        '' // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        '/' // Prevent entering more than 1 `/`
      );
  }
  public toTermOfService() {
    this.$router.push({ name: 'PHelpCenter', params: { info: 'info' } });
    this.cancel();
  }

  public cancel() {
    if (this.isBackTopUp) {
      this.localPayload = Object.assign({}, this.localPayloadBak);
      this.visibleVA = false;
      this.isBackTopUp = false;
      this.visibleOptionPayment = false;
      this.visibleOptionVA = false;
      this.localPayload.payTypes = [];
      this.checkOrderDetail();
    } else {
      this.$emit('cancel');
    }
  }

  public retrievePayOptionVa() {
    this.pPayService()
      .retrievePayOptionVA()
      .then(res => {
        this.payOptionsVA = res;
      })
      .catch(err => {
        if (err.response.status === 404) {
          this.alertService().showHttpError(this, err.response);
        }
      });
  }

  public async beforeSubmit() {
    // validation submit disabled
    if (this.submitDisabled) return;

    if (this.localPayload.payMethodType.includes('_DETAIL')) {
      this.localPayload.payMethodType = <PayMethodType>this.localPayload.payMethodType.split('_DETAIL')[0];
    }
    if (!this.localPayload.payTypes) {
      this.localPayload.payTypes = [];
    }

    if (this.localPayload.payTypes.includes(PayType.VA) && this.orderDetail.totalAmountMustPaid < VA_REMAINING_MINIMUM) {
      if (this.localPayload.payMethodType == PayMethodType.ORDER_PAW) {
        this.localPayload.payMethodType = PayMethodType.ORDER_PAW_DETAIL;
      } else if (this.localPayload.payMethodType == PayMethodType.TOP_UP) {
        this.localPayload.payMethodType = PayMethodType.TOP_UP_DETAIL;
      }

      return this.sendToast(this.$t('cbgwApp.accountBalance.minimalVaRemaining').toString(), TOAST_WARNING);
    }

    if (
      (this.localPayload.payTypes.includes(PayType.BALANCE) ||
        this.localPayload.payTypes.includes(PayType.BALANCE_REFUND) ||
        this.localPayload.payTypes.includes(PayType.BALANCE_AUCTION_GUARANTEE) ||
        this.localPayload.payTypes.includes(PayType.CC) ||
        this.localPayload.payMethodType == PayMethodType.DISBURSEMENT ||
        this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME ||
        this.localPayload.payMethodType == PayMethodType.REFUND_REQUEST) &&
      this.localPayload.payMethodType != PayMethodType.TOP_UP &&
      !this.localPayload.externalPin
    ) {
      if (
        (!this.currentUser.pinActivated && !this.currentUser.isPinExist && !this.localPayload.payTypes.includes(PayType.CC)) ||
        (this.newCbBankAccount.isSave &&
          this.localPayload.payTypes.includes(PayType.CC) &&
          !this.currentUser.pinActivated &&
          !this.currentUser.isPinExist)
      ) {
        this.openModalSetPin();
      } else {
        // not show visiblePin when payTypes includes only PayType.CC
        if (
          this.localPayload.payTypes.includes(PayType.CC) &&
          !this.localPayload.payTypes.includes(PayType.BALANCE) &&
          !this.localPayload.payTypes.includes(PayType.BALANCE_REFUND) &&
          !this.localPayload.payTypes.includes(PayType.BALANCE_AUCTION_GUARANTEE)
        ) {
          this.submit();
          return;
        } else if (!this.currentUser.pinActivated && !this.currentUser.isPinExist) {
          this.openModalSetPin();
        } else {
          this.visiblePin = true;
          this.pinNotMatch = false;
        }
      }

      return;
    }

    if (this.localPayload.payMethodType == PayMethodType.TOP_UP) {
      if (this.orderDetail.limitAmount && this.localPayload.amount) {
        const maxValue = this.orderDetail.limitAmount + this.localPayload.amount;
        if (maxValue > this.topupLimit) {
          return this.sendToast(this.$t('cbgwApp.accountBalance.reachTopupLimit').toString(), TOAST_WARNING);
        }
      }

      await this.createPaymentTopup().then(res => {
        this.submit();
      });
    } else {
      this.submit();
    }
  }

  public async submitAfterPin() {
    if (
      this.localPayload.payMethodType == PayMethodType.DISBURSEMENT ||
      this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME ||
      this.localPayload.payMethodType == PayMethodType.REFUND_REQUEST
    ) {
      await this.createWithdraw().then(res => {
        this.submit();
      });
    } else {
      this.submit();
    }
  }

  public prepareSubmitPayTypeCC(localPayload: IPayPayload2023, tmpBankAccount: ICbBankAccount): IPayPayload2023 {
    this.localPayload.cardDataAccountNumber = tmpBankAccount.accountNumber.replace(/\s/g, '');
    this.localPayload.cardDataCvn = tmpBankAccount.cvc.toString();

    const arrayExpiredDate: string[] = tmpBankAccount.expiryDateLabel.split('/');
    this.localPayload.cardDataExpMonth = arrayExpiredDate[0];
    this.localPayload.cardDataExpYear = dayjs().toDate().getFullYear().toString().slice(0, 2) + arrayExpiredDate[1];
    this.localPayload.accountHolderName = tmpBankAccount.accountHolderName;
    return localPayload;
  }

  public async submit() {
    if (this.localPayload.payTypes.includes(PayType.CC)) {
      let tmpBankAccount: ICbBankAccount = new CbBankAccount();
      if (this.selectedBankAccountId == 'NEWCC') {
        tmpBankAccount = Object.assign({}, this.newCbBankAccount);
      }
      if (Number(this.selectedBankAccountId)) {
        tmpBankAccount = this.cbBankAccounts.find(e => e.id == Number(this.selectedBankAccountId));
      }
      if (this.deletedBankAccountIds && this.deletedBankAccountIds.length > 0) {
        await this.deleteBankCC();
      }
      if (tmpBankAccount.isSave) {
        await this.saveBankCC(tmpBankAccount);
      }

      this.localPayload = Object.assign({}, this.prepareSubmitPayTypeCC(this.localPayload, tmpBankAccount));
    }
    this.isSavingPayment = true;

    if (this.localPayload.payMethodType == PayMethodType.ORDER_PAW) {
      // WF
      this.wfGetWfHistoryByPayment(this.localPayload)
        .then(wfHistory => {
          console.log(wfHistory);
          this.pPayService()
            .pay2023(this.localPayload, wfHistory ? wfHistory : null)
            .then(res => {
              this.processResponseSubmitPayment(res);
              console.log('local payload', this.localPayload);
            })
            .catch(err => {
              if (err.response.status < 500) {
                this.processResponseSubmitPayment(err.response.data);
              } else {
                this.alertService().showHttpError(this, err.response);
              }
            })
            .finally(() => {
              this.isSavingPayment = false;
            });
        })
        .catch(err => {
          this.isSavingPayment = false;
        });
    } else {
      this.pPayService()
        .pay2023(this.localPayload)
        .then(res => {
          this.processResponseSubmitPayment(res);
          console.log('local payload', this.localPayload);
        })
        .catch(err => {
          if (err.response.status < 500) {
            this.processResponseSubmitPayment(err.response.data);
          } else {
            this.alertService().showHttpError(this, err.response);
          }
        })
        .finally(() => {
          this.isSavingPayment = false;
        });
    }
  }

  public processResponseSubmitPayment(res: any) {
    if (res.id == 1) {
      this.processResponseSubmitPaymentDetail(res);
    }
    if (res.id == -1 && res.message) {
      let message: string;

      switch (res.message) {
        case 'still have active va for this transaction':
          message = 'cbGlobal.toast.thereIsStillActiveVAForThisTransaction';
          break;
        case 'Min Topup 15.000':
          message = 'cbGlobal.toast.minimumTopUp';
          break;
        case 'Max Topup 20.000.000':
          message = 'cbGlobal.toast.maximumTopUp';
          break;
        case 'Min Va Amount 10.000':
          message = 'cbGlobal.toast.minimumAmountForVATransaction';
          break;
        case 'Max CB Balance 20.000.000':
          message = 'cbGlobal.toast.maximumKlikjobBalance';
          break;
        case 'Amount of topups has exceeded the maximum limit per month':
          message = 'cbGlobal.toast.topUpAmountHasExceededMaximumMonthlyLimit';
          break;
        case 'This transaction has already paid':
          message = 'cbGlobal.toast.thisTransactionHasBeenPaid';
          break;
        case 'Order Expired':
          message = 'cbGlobal.toast.orderExpired';
          break;
        case 'Min CC Amount 5000':
          message = 'cbGlobal.toast.minimumAmountForCreditCardTransaction';
          break;
        case 'Max CC Amount 200.000.000':
          message = 'cbGlobal.toast.maximumAmountForCreditCardTransaction';
          break;
        case 'Promo can only be used with one payment method':
          message = 'cbGlobal.toast.appliedPromoCanOnlyBeUsedWithOnePaymentMethod';
          break;
        case 'Can not pay order cancelled':
          message = 'cbGlobal.toast.canceledOrderCannotBePaid';
          break;
        case 'Can not pay order completed':
          message = 'cbGlobal.toast.completedOrderCannotBePaid';
          break;
        case 'Promo can only be used by PAW order type':
          message = 'cbGlobal.toast.promoCanOnlyBeUsedByPAWOrderType';
          break;
        case 'Min Withdraw 50.000':
          message = 'cbGlobal.toast.minimumWithdraw';
          break;
        case 'Max Withdraw 50.000.000.000':
          message = 'cbGlobal.toast.maximumWithdraw';
          break;
        case 'Insufficient Amount':
          message = 'cbGlobal.toast.insufficientBalance';
          break;
        default:
          message = res.message;
          break;
      }

      this.sendToast(message.includes('cbGlobal') ? this.$t(message).toString() : message, 'warning');
      this.resetPayMethodType();
    }
    if (res.id == -1 && !res.message) {
      this.sendToast(this.$t('cbGlobal.toast.paymentFailed').toString(), 'warning');
      this.resetPayMethodType();
    }
  }

  public processResponseSubmitPaymentDetail(res: any) {
    if (res.detail) {
      if (this.isBackTopUp) {
        this.payDetailVA = Object.assign({}, res.detail);
        this.visibleVA = true;
        return;
      } else if (
        res.detail?.payMethodType == PayMethodType.DISBURSEMENT ||
        res.detail?.payMethodType == PayMethodType.DISBURSEMENT_DETAIL ||
        res.detail?.payMethodType == PayMethodType.DISBURSEMENT_INCOME ||
        res.detail?.payMethodType == PayMethodType.DISBURSEMENT_INCOME_DETAIL ||
        res.detail?.payMethodType == PayMethodType.REFUND_REQUEST ||
        res.detail?.payMethodType == PayMethodType.REFUND_REQUEST_DETAIL
      ) {
        this.$emit('onSuccess');
        return;
      }

      if (this.localPayload.payTypes.includes(PayType.VA)) {
        this.$emit('callbackVA', res.detail);
        console.log('local payload VA', this.localPayload);
      } else if (this.localPayload.payTypes.includes(PayType.CC)) {
        this.$emit('callbackCC', res.detail);
      } else {
        this.sendToast(this.$t('cbGlobal.toast.paymentSuccess').toString(), 'success');
        this.$emit('onSuccess');
      }
    } else {
      this.sendToast(this.$t('cbGlobal.toast.paymentSuccess').toString(), 'success');
      this.$emit('onSuccess');
    }
  }

  public sendToast(message: string, type: string) {
    if (type == TOAST_WARNING) {
      (<any>this.$root).$bvToast.toast(message, {
        toaster: 'b-toaster-top-center',
        title: 'Warning',
        variant: 'warning',
        solid: true,
        autoHideDelay: 5000,
      });
    }
    if (type == TOAST_SUCCESS) {
      (<any>this.$root).$bvToast.toast(message, {
        toaster: 'b-toaster-top-center',
        title: 'Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 5000,
      });
    }
  }

  public openModalSetPin() {
    this.pCbUserPinService()
      .postSetPin()
      .then(res => {
        const cbVerificationOtp: ICbVerificationOtp = res.detail;
        cbVerificationOtp.setPinType = SetPinType.SET_PIN;
        this.$root.$emit('openModalSetPin', cbVerificationOtp);
        this.cancelInputPin();
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      });
  }

  public cancelInputPin() {
    this.visiblePin = false;
    this.pinNotMatch = false;
    if (this.localPayload.payMethodType == PayMethodType.ORDER_PAW) {
      this.localPayload.payMethodType = PayMethodType.ORDER_PAW_DETAIL;
    }

    if (this.localPayload.payMethodType == PayMethodType.ORDER_AUCTION) {
      this.localPayload.payMethodType = PayMethodType.ORDER_AUCTION_DETAIL;
    }

    if (this.localPayload.payMethodType == PayMethodType.DISBURSEMENT) {
      this.localPayload.payMethodType = PayMethodType.DISBURSEMENT_DETAIL;
    }

    if (this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME) {
      this.localPayload.payMethodType = PayMethodType.DISBURSEMENT_INCOME_DETAIL;
    }
  }

  public resetPayMethodType() {
    this.localPayload.payMethodType = <PayMethodType>(this.localPayload.payMethodType + '_DETAIL');
  }
  public async createPaymentTopup() {
    let cbPayMethodType: CbPayMethodType = null;
    if (this.localPayload.payTypes.includes(PayType.VA)) {
      cbPayMethodType = CbPayMethodType.VA;
    }
    if (this.localPayload.payTypes.includes(PayType.CC)) {
      cbPayMethodType = CbPayMethodType.CC;
    }

    const cbTopup: ICbTopup = new CbTopup();
    cbTopup.status = CbPaymentStatus.WAITING_FOR_PAYMENT;
    cbTopup.topupAmount = this.localPayload.amount;
    cbTopup.cbPayMethodType = cbPayMethodType;

    this.isSavingTopup = true;
    await this.pCbTopupService()
      .create(cbTopup)
      .then(res => {
        this.localPayload.orderId = res.id;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      })
      .finally(() => {
        this.isSavingTopup = false;
      });
  }

  public async createWithdraw() {
    this.isSavingWithdraw = true;
    let tmpBankAccount: ICbBankAccount = new CbBankAccount();

    if (Number(this.selectedBankAccountId)) {
      tmpBankAccount = this.cbBankAccounts.find(e => e.id == Number(this.selectedBankAccountId));
    }

    if (tmpBankAccount.id) {
      let cbWithdraw: ICbWithdraw = new CbWithdraw();
      cbWithdraw.bankCode = tmpBankAccount.bankCode;
      cbWithdraw.accountHolderName = tmpBankAccount.accountHolderName;
      cbWithdraw.accountNumber = tmpBankAccount.accountNumber.replace(/\s/g, '');
      cbWithdraw.withdrawAmount = this.localPayload.amount;
      await this.pCbWithdrawService()
        .create(cbWithdraw)
        .then(res => {
          cbWithdraw = Object.assign({}, res);
          this.localPayload.orderId = cbWithdraw.id;
        })
        .catch(error => {
          this.alertService().showHttpError(this, error.response);
        })
        .finally(() => {
          this.isSavingWithdraw = false;
        });
    }
  }

  public async saveBankCC(bankAccount: ICbBankAccount) {
    let expiredDate: Date = dayjs().toDate();

    expiredDate = dayjs(expiredDate)
      .set('month', +Number(bankAccount.expiryDateLabel.split('/')[0]) - 1)
      .toDate();
    expiredDate = dayjs(expiredDate)
      .set('year', Number(dayjs().toDate().getFullYear().toString().slice(0, 2) + bankAccount.expiryDateLabel.split('/')[1]))
      .toDate();
    if (this.localPayload.externalPin) {
      bankAccount.pin = this.localPayload.externalPin;
    }
    bankAccount.bankAccountType = BankAccountType.CREDIT;
    bankAccount.expiredDate = expiredDate;
    if (bankAccount.accountNumber) {
      bankAccount.accountNumber = bankAccount.accountNumber.split(' ').join('');
    }
    if (!bankAccount.id) {
      await this.pCbBankAccountService()
        .createForPaymentCredit(bankAccount)
        .catch(err => {
          this.alertService().showHttpError(this, err.response);
        });
    } else {
      await this.pCbBankAccountService()
        .partialUpdateForPaymentCredit(bankAccount)
        .catch(err => {
          this.alertService().showHttpError(this, err.response);
        });
    }
  }

  public async deleteBankCC() {
    for (const id of this.deletedBankAccountIds) {
      await this.pCbBankAccountService().delete(id);
    }
  }

  public deleteLocalBankAccount(id: number) {
    this.deletedBankAccountIds.push(id);
    const foundIndex = this.cbBankAccounts.findIndex(e => e.id == id);
    if (foundIndex >= 0) {
      this.deleteCbBankAccount(this.cbBankAccounts[foundIndex]);
    }
    this.selectedBankAccountId = null;
    const foundIndexPayType = this.localPayload.payTypes.findIndex(e => e == PayType.CC);
    if (foundIndexPayType >= 0) {
      Vue.delete(this.localPayload.payTypes, foundIndex);

      this.checkOrderDetail();
      this.processRetrieveCbVwPromoUserAggs();
    }
  }

  // delete bank account cc
  public deleteCbBankAccount(bankAccount: ICbBankAccount) {
    this.pCbBankAccountService()
      .delete(bankAccount.id)
      .then(res => {
        const foundIndex = this.cbBankAccounts.findIndex(e => e.id == bankAccount.id);
        if (foundIndex >= 0) {
          Vue.delete(this.cbBankAccounts, foundIndex);
        }
      })
      .catch(err => console.log('ERROR', err.response));
  }

  public toHelpCenter() {
    this.$router.push({ name: 'PHelpCenter', params: { info: 'info' } });
    this.globalService().closeGlobalDialog(this.$root, 'modal-is-show-form-payment-top-up');
  }

  public checkPin(pin: string) {
    this.pCbUserPinService()
      .postCurrentPin({ currentPin: pin })
      .then(async res => {
        this.pinNotMatch = false;
        this.visiblePin = false;
        this.localPayload.externalPin = pin;
        if (this.localPayload.payMethodType == PayMethodType.ORDER_PAW_DETAIL) {
          this.localPayload.payMethodType = PayMethodType.ORDER_PAW;
        }

        if (this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_DETAIL) {
          this.localPayload.payMethodType = PayMethodType.DISBURSEMENT;
        }

        if (this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME_DETAIL) {
          this.localPayload.payMethodType = PayMethodType.DISBURSEMENT_INCOME;
        }

        await this.submitAfterPin();
      })
      .catch(err => {
        this.pinNotMatch = true;
        this.localPayload.externalPin = null;

        if (err.response.status >= 400) {
          if (this.localPayload.payMethodType == PayMethodType.ORDER_PAW) {
            this.localPayload.payMethodType = PayMethodType.ORDER_PAW_DETAIL;
          }

          if (this.localPayload.payMethodType == PayMethodType.DISBURSEMENT) {
            this.localPayload.payMethodType = PayMethodType.DISBURSEMENT_DETAIL;
          }

          if (this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME) {
            this.localPayload.payMethodType = PayMethodType.DISBURSEMENT_INCOME_DETAIL;
          }
        }
      });
  }

  @Debounce(400)
  public debounceCheckOrderDetail() {
    this.checkOrderDetail();
  }

  public toTopUp() {
    this.isFetchingOrderDetail = true;
    const promoAmount = this.orderDetail.promoAmount ?? 0;

    if (this.localPayload.payTypes.includes(PayType.VA) || this.localPayload.payTypes.includes(PayType.CC)) {
      this.localPayload.payTypes = this.localPayload.payTypes.filter(e => e != PayType.VA && e != PayType.CC);

      this.localPayload.bankCode = null;
    }

    if (this.orderDetail?.pgAmountPaid > 0) {
      this.orderDetail.rawAmount -= this.orderDetail.pgAmountPaid;
    }

    if (this.orderDetail.rawAmount) {
      if (this.localPayload?.payTypes.length > 0) {
        if (
          this.orderDetail.rawAmount - promoAmount >= this.orderDetail.balanceAmount &&
          this.localPayload?.payTypes.length == 1 &&
          this.localPayload?.payTypes[0] == PayType.BALANCE
        ) {
          this.localPayload.amount = this.orderDetail.rawAmount - promoAmount - this.orderDetail.balanceAmount;
        } else if (
          this.orderDetail.rawAmount - promoAmount >= this.orderDetail.refundAmount + this.orderDetail.balanceAmount &&
          this.localPayload?.payTypes.length == 1 &&
          this.localPayload?.payTypes[0] == PayType.BALANCE_REFUND
        ) {
          this.localPayload.amount =
            this.orderDetail.rawAmount - promoAmount - (this.orderDetail.refundAmount + this.orderDetail.balanceAmount);
        } else if (
          this.orderDetail.rawAmount - promoAmount >= this.orderDetail.refundAmount + this.orderDetail.balanceAmount &&
          this.localPayload?.payTypes.length == 2 &&
          this.localPayload?.payTypes.includes(PayType.BALANCE) &&
          this.localPayload?.payTypes.includes(PayType.BALANCE_REFUND)
        ) {
          this.localPayload.amount =
            this.orderDetail.rawAmount - promoAmount - (this.orderDetail.refundAmount + this.orderDetail.balanceAmount);
        } else {
          this.localPayload.amount = this.localPayload?.payTypes.includes(PayType.BALANCE_AUCTION_GUARANTEE)
            ? this.orderDetail.rawAmount
            : 0;
        }
      } else {
        if (this.orderDetail.rawAmount - promoAmount >= this.orderDetail.balanceAmount) {
          this.localPayload.amount = this.orderDetail.rawAmount - promoAmount - this.orderDetail.balanceAmount;
        } else {
          this.localPayload.amount = 0;
        }
      }
    }

    this.localPayloadBak = Object.assign({}, this.localPayload);
    this.localPayload.payMethodType = PayMethodType.TOP_UP_DETAIL;
    this.localPayload.payTypes = [PayType.VA];
    this.localPayload.title = 'Topup';
    this.localPayload.subTitle = '';

    this.isBackTopUp = true;
    this.checkOrderDetail();
  }

  public handleCheckHolderName(username: string) {
    if (username) {
      this.newCbBankAccount.accountHolderName = username.replace(/[^A-Za-z ]/gi, '');
    }
  }

  public processPromoMethodAndPayType(cbVwPromoUserAggs: ICbVwPromoUserAgg[], paytype: PayType) {
    for (const cbVwPromoUserAgg of cbVwPromoUserAggs) {
      if (
        (paytype == PayType.BALANCE_REFUND || paytype == PayType.BALANCE_AUCTION_GUARANTEE) &&
        cbVwPromoUserAgg.payPromoMethod != CbPromoPayMethod.ALL
      ) {
        cbVwPromoUserAgg.isValidWithPayType = false;
      }
    }

    return cbVwPromoUserAggs;
  }

  public promoDetail(cbVwPromo: ICbVwPromo) {
    cbVwPromo.isUserHasClaimed = true;
    cbVwPromo.isUserHasUsed = false;
    this.cbVwPromo = cbVwPromo;
    this.isOpenPromoDetail = true;
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get checkedBalance() {
    return (paytype: PayType) => {
      if (!this.localPayload.payTypes) {
        this.localPayload.payTypes = [];
      }
      if (this.localPayload.payTypes.includes(paytype)) {
        return true;
      } else return false;
    };
  }

  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }

  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }

  public get getPayOptionVa() {
    return this.payOptionsVA.filter(
      e =>
        e.code == CbListBank.BCA ||
        e.code == CbListBank.BNI ||
        e.code == CbListBank.BRI ||
        e.code == CbListBank.MANDIRI ||
        e.code == CbListBank.BSI
    );
  }

  public get getPayTypeIncludes() {
    return (payType: PayType) => {
      if (!this.localPayload.payTypes) {
        this.localPayload.payTypes = [];
      }
      if (this.localPayload.payTypes.includes(payType)) {
        return true;
      }
      return false;
    };
  }

  public get isMobile() {
    return this.$store.getters.isMobile;
  }

  public get submitDisabled() {
    let value = false;

    if (this.isFetchingOrderDetail || this.isSavingPayment || this.isSavingTopup || this.isSavingWithdraw) {
      value = true;
    }

    // TOPUP
    if (this.localPayload?.payMethodType?.toString().includes(PayMethodType.TOP_UP.toString())) {
      if (!this.localPayload.amount || this.localPayload.amount < 15000) {
        value = true;
      }
      if (this.localPayload?.amount > 20000000) {
        value = true;
      }
      if (!this.localPayload.bankCode) {
        value = true;
      }
      if (!this.localPayload?.payTypes?.includes(PayType.VA)) {
        value = true;
      }
      if (this.orderDetail?.limitAmount > 40000000) {
        value = true;
      }
    }

    // WITHDRAW
    if (
      this.localPayload.payMethodType.toString().includes(PayMethodType.DISBURSEMENT.toString()) ||
      this.localPayload.payMethodType.toString().includes(PayMethodType.REFUND_REQUEST.toString())
    ) {
      if (!this.localPayload.amount || this.localPayload.amount < 50000) {
        value = true;
      }
      if (this.localPayload?.amount > 50000000000) {
        value = true;
      }

      if (!this.cbBankAccounts || this.cbBankAccounts.length == 0) {
        value = true;
      }
      if (this.cbBankAccounts?.length > 0) {
        const foundDebit = this.cbBankAccounts.find(e => e.bankAccountType == BankAccountType.DEBIT);
        if (!foundDebit) {
          value = true;
        }
      }

      if (
        (this.localPayload.payMethodType == PayMethodType.DISBURSEMENT ||
          this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_DETAIL) &&
        this.orderDetail.balanceAmount < this.localPayload.amount
      ) {
        value = true;
      }
      if (
        (this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME ||
          this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME_DETAIL) &&
        this.orderDetail.incomeAmount < this.localPayload.amount
      ) {
        value = true;
      }
      if (
        (this.localPayload.payMethodType == PayMethodType.REFUND_REQUEST ||
          this.localPayload.payMethodType == PayMethodType.REFUND_REQUEST_DETAIL) &&
        this.orderDetail.refundAmount < this.localPayload.amount
      ) {
        value = true;
      }
    }

    // ORDER AUCTION
    if (this.localPayload.payMethodType.toString().includes(PayMethodType.ORDER_AUCTION.toString())) {
      if (!this.localPayload.payTypes || this.localPayload.payTypes.length == 0) {
        value = true;
      }
      if (this.orderDetail?.totalAmountMustPaid > 0) {
        value = true;
      }
    }

    // ORDER PAW
    if (this.localPayload.payMethodType.toString().includes(PayMethodType.ORDER_PAW.toString())) {
      if (!this.localPayload.payTypes || this.localPayload.payTypes.length == 0) {
        value = true;
      }

      if (
        this.orderDetail?.totalAmountMustPaid > 0 &&
        !this.localPayload.payTypes.includes(PayType.VA) &&
        !this.localPayload.payTypes.includes(PayType.CC)
      ) {
        value = true;
      }

      if (this.orderDetail?.totalAmountMustPaid > 0 && this.localPayload.payTypes.includes(PayType.CC)) {
        if (!this.selectedBankAccountId) {
          value = true;
        }

        if (this.selectedBankAccountId != 'NEWCC') {
          const foundBank = this.cbBankAccounts.find(e => e.id == Number(this.selectedBankAccountId));
          if (!foundBank?.accountNumber || foundBank?.accountNumber.split(' ').join('').length < 16) {
            value = true;
          }
          if (!foundBank?.expiryDateLabel || foundBank?.expiryDateLabel.length < 5) {
            value = true;
          }

          if (!foundBank?.cvc || foundBank?.cvc.toString().length != 3) {
            value = true;
          }
          if (!foundBank?.accountHolderName) {
            value = true;
          }
          if (foundBank?.expiredDate && dayjs(foundBank?.expiredDate).toDate() < dayjs().toDate()) {
            value = true;
          }
        }

        if (this.selectedBankAccountId == 'NEWCC') {
          if (!this.newCbBankAccount?.accountNumber || this.newCbBankAccount?.accountNumber?.split(' ').join('').length < 16) {
            value = true;
          }
          if (!this.newCbBankAccount?.expiryDateLabel || this.newCbBankAccount?.expiryDateLabel?.length < 5) {
            value = true;
          }
          if (!this.newCbBankAccount?.cvc || this.newCbBankAccount?.cvc?.toString().length != 3) {
            value = true;
          }
          if (!this.newCbBankAccount?.accountHolderName) {
            value = true;
          }
          if (this.newCbBankAccount?.expiryDateLabel?.length >= 5) {
            let expiredDate: Date = dayjs().toDate();
            const now = dayjs().toDate();

            expiredDate = dayjs(expiredDate)
              .set('month', +Number(this.newCbBankAccount.expiryDateLabel.split('/')[0]) - 1)
              .toDate();
            expiredDate = dayjs(expiredDate)
              .set(
                'year',
                Number(dayjs().toDate().getFullYear().toString().slice(0, 2) + this.newCbBankAccount.expiryDateLabel.split('/')[1])
              )
              .toDate();
            if (expiredDate <= now) {
              value = true;
            }
          }
        }
      }
    }

    return value;
  }

  public get validationCC() {
    return (field: string) => {
      if (field == 'accountNumber') {
        if (!this.newCbBankAccount.accountNumber || this.newCbBankAccount.accountNumber.split(' ').join('').length < 16) {
          return true;
        }
      }
      if (field == 'expiryDateLabel') {
        if (!this.newCbBankAccount.expiryDateLabel || this.newCbBankAccount.expiryDateLabel.length < 5) {
          return true;
        }
      }
      if (field == 'cvc') {
        if (!this.newCbBankAccount.cvc || this.newCbBankAccount.cvc.toString().length != 3) {
          return true;
        }
      }
      if (field == 'accountHolderName') {
        if (!this.newCbBankAccount.accountHolderName) {
          return true;
        }
      }
      return false;
    };
  }

  public get getStatusExpiredLabel() {
    return (cbCreditCard: ICbBankAccount) => {
      const now = dayjs().toDate();
      if (cbCreditCard.expiryDateLabel && cbCreditCard.expiryDateLabel.length >= 5) {
        let expiredDate: Date = dayjs().toDate();

        expiredDate = dayjs(expiredDate)
          .set('month', +Number(cbCreditCard.expiryDateLabel.split('/')[0]) - 1)
          .toDate();

        expiredDate = dayjs(expiredDate)
          .set('year', Number(dayjs().toDate().getFullYear().toString().slice(0, 2) + cbCreditCard.expiryDateLabel.split('/')[1]))
          .toDate();
        if (expiredDate <= now) {
          return true;
        }
      }
      return false;
    };
  }

  public get maxAmountWithdraw() {
    if (this.orderDetail) {
      if (
        this.orderDetail.balanceAmount &&
        (this.localPayload.payMethodType == PayMethodType.DISBURSEMENT ||
          this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_DETAIL)
      ) {
        return this.orderDetail.balanceAmount;
      }
      if (
        this.orderDetail.refundAmount &&
        (this.localPayload.payMethodType == PayMethodType.REFUND_REQUEST ||
          this.localPayload.payMethodType == PayMethodType.REFUND_REQUEST_DETAIL)
      ) {
        return this.orderDetail.refundAmount;
      }
      if (
        this.orderDetail.incomeAmount &&
        (this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME ||
          this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME_DETAIL)
      ) {
        return this.orderDetail.incomeAmount;
      }
    }
    return 0;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('payload')
  public async payloadListener() {
    if (this.payload) {
      this.localPayload = { ...this.localPayload, ...this.payload };

      if (this.localPayload?.cbVwPawOrderRequirement?.requirementType === CbRequirementType.MOM) {
        this.pawTitlePrice = `Additional - ${this.localPayload.cbVwPawOrderRequirement?.title}`;
      } else if (this.localPayload?.cbVwPawOrderRequirement?.requirementType === CbRequirementType.REVISION_EXTRA) {
        this.pawTitlePrice = 'cbgwApp.cbComponent.cFromPayment.additionalExtra';
      } else if (this.localPayload?.cbVwPawOrder?.orderType === CbOrderType.PAW) {
        this.pawTitlePrice = 'cbgwApp.cbComponent.cFromPayment.pawPrice';
      } else if (this.localPayload?.cbVwPawOrder?.orderType === CbOrderType.AUCTION_PROJECT) {
        this.pawTitlePrice = 'cbgwApp.cbComponent.cFromPayment.auctionPrice';
      }

      await this.checkOrderDetail();
      if (!this.localPayload.payTypes) {
        this.localPayload.payTypes = [];
      }
      if (this.localPayload.payMethodType.toString().includes('TOP_UP')) {
        this.visibleOptionPayment = true;
        this.visibleOptionVA = true;
        this.retrievePayOptionVa();
      }
      if (this.localPayload.payMethodType.toString().includes('ORDER')) {
        if (this.localPayload.payMethodType.toString().includes('PAW')) {
          this.retrieveCbVwPromoUserAggService(PayType.ALL);
        }
        this.retrievePayOptionVa();
        this.retrieveCbBankAccount();
      }

      if (
        this.localPayload.payMethodType == PayMethodType.DISBURSEMENT ||
        this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_DETAIL ||
        this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME ||
        this.localPayload.payMethodType == PayMethodType.DISBURSEMENT_INCOME_DETAIL ||
        this.localPayload.payMethodType == PayMethodType.REFUND_REQUEST ||
        this.localPayload.payMethodType == PayMethodType.REFUND_REQUEST_DETAIL
      ) {
        this.retrieveCbBankAccount();
      }
    }
  }

  public get isDeviceSize() {
    return this.$store.getters.isDeviceSize;
  }

  @Watch('localPayload.payMethodType')
  public localPayloadListener() {
    if (this.localPayload.payMethodType == PayMethodType.TOP_UP || this.localPayload.payMethodType == PayMethodType.TOP_UP_DETAIL) {
      this.visibleOptionPayment = true;
      this.visibleOptionVA = true;
    }
  }

  public get isInSufficientBalance() {
    // if auction
    if (
      this.localPayload.payMethodType == PayMethodType.ORDER_AUCTION ||
      this.localPayload.payMethodType == PayMethodType.ORDER_AUCTION_DETAIL
    ) {
      // if paytypes includes BALANCE and BALANCE REFUND and amount > balance + balance refund
      if (this.localPayload.payTypes.includes(PayType.BALANCE) && this.localPayload.payTypes.includes(PayType.BALANCE_REFUND)) {
        if (this.orderDetail.orderAmount > this.orderDetail.balanceAmount + this.orderDetail.refundAmount) {
          return true;
        }
      }

      // if paytypes includes BALANCE  and amount > balance
      if (this.localPayload.payTypes.length == 1 && this.localPayload.payTypes.includes(PayType.BALANCE)) {
        if (this.orderDetail.orderAmount > this.orderDetail.balanceAmount) {
          return true;
        }
      }

      // if paytypes includes  BALANCE REFUND and amount >  balance refund
      if (this.localPayload.payTypes.length == 1 && this.localPayload.payTypes.includes(PayType.BALANCE_REFUND)) {
        if (this.orderDetail.orderAmount > this.orderDetail.refundAmount) {
          return true;
        }
      }
    }

    return false;
  }
  // ================= END LISTENERS ==================

  // new

  public visibleOptionCC = false;
  public handleVisibleOptionVA() {
    this.visibleOptionVA = !this.visibleOptionVA;
    // kondisinya progress 70%
    // if(!this.visibleOptionVA || this.visibleOptionCC){
    //   this.localPayload.payTypes = [];
    // }
  }
  public handleVisibleOptionCC() {
    this.visibleOptionCC = !this.visibleOptionCC;
    // if(!this.visibleOptionCC || this.visibleOptionVA){
    //   this.localPayload.payTypes = [];
    // }
    // if(this.cbBankAccounts && this.cbBankAccounts.length == 0){
    //   this.handleClickCC('NEWCC')
    // }
  }
}
