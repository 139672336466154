var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-input-otp" }, [
    _c("div", { staticClass: "custom-input-otp my-4 my-lg-5" }, [
      _c("input", {
        ref: `otpFirst${_vm.refOtp}`,
        staticClass:
          "input-otp-wrapper cj-field-border-bottom-default cj-field-input",
        attrs: {
          id: "otp-first",
          type: "text",
          maxlength: "1",
          step: "1",
          "aria-label": "first digit",
          "data-cy": "first_digit",
        },
      }),
      _vm._v(" "),
      _c("input", {
        staticClass:
          "input-otp-wrapper cj-field-border-bottom-default cj-field-input",
        attrs: {
          id: "otp-second",
          type: "text",
          maxlength: "1",
          step: "1",
          "aria-label": "second digit",
          "data-cy": "second_digit",
        },
      }),
      _vm._v(" "),
      _c("input", {
        staticClass:
          "input-otp-wrapper cj-field-border-bottom-default cj-field-input",
        attrs: {
          id: "otp-third",
          type: "text",
          maxlength: "1",
          step: "1",
          "aria-label": "third digit",
          "data-cy": "third_digit",
        },
      }),
      _vm._v(" "),
      _c("input", {
        staticClass:
          "input-otp-wrapper cj-field-border-bottom-default cj-field-input",
        attrs: {
          id: "otp-fourth",
          type: "text",
          maxlength: "1",
          step: "1",
          "aria-label": "fourth digit",
          "data-cy": "fourth_digit",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }