import Vue from 'vue';
import DOMPurify from 'dompurify';

export function initSecurityFilters() {
  Vue.filter('SECURITY_VHTML', value => {
    const allowedStyles = ['color', 'font-size', 'margin'];

    // Fungsi untuk membersihkan style inline pada elemen yang berisi "style" atribut
    function sanitizeStyle(input) {
      const styleObj = input.split(';').reduce((acc, style) => {
        const [property, value] = style.split(':');
        if (allowedStyles.includes(property.trim())) {
          acc.push(`${property.trim()}: ${value.trim()}`);
        }
        return acc;
      }, []);
      return styleObj.join('; ');
    }

    // Fungsi untuk membersihkan inline CSS
    function sanitizeInlineStyles(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const elementsWithStyle = doc.querySelectorAll('[style]');

      elementsWithStyle.forEach(element => {
        const sanitizedStyle = sanitizeStyle(element.getAttribute('style'));
        element.setAttribute('style', sanitizedStyle);
      });

      return doc.body.innerHTML;
    }

    if (value) {
      // Langkah 1: Sanitasi HTML dengan DOMPurify, blokir elemen yang tidak diizinkan
      const sanitizedHtml = DOMPurify.sanitize(value, {
        FORBID_TAGS: [
          'textarea',
          'form',
          'input',
          'button',
          'select',
          'option',
          'optgroup',
          'fieldset',
          'legend',
          'label',
          'datalist',
          'keygen',
          'output',
          'svg',
        ], // Mencegah elemen yang tidak diizinkan
      });

      // Langkah 2: Sanitasi inline CSS di dalam HTML
      const finalSanitizedHtml = sanitizeInlineStyles(sanitizedHtml);

      return finalSanitizedHtml;
    }
  });

  Vue.filter('SECURITY_VHTML_AND_NOTIF_REPLACE_BASE_URL', (value, notifRepaceBaseUrl) => {
    if (value) {
      let dataValue = DOMPurify.sanitize(value);
      dataValue = dataValue.replace(/<a /g, '<span ').replace(/<\/a>/g, '</span>');
      const urlArray: string[] = notifRepaceBaseUrl.split(',');

      // Iterate over the array
      for (const url of urlArray) {
        console.log(url);
        dataValue = dataValue.replace(`href="` + url, `href="`);
        // Your logic for each URL goes here
      }
      if (dataValue) {
        return dataValue;
      }
    }
  });
}
